<template>
  <v-dialog v-model="clientAgreementStore.selectedTierTypeForm" width="800">
    <v-card color="#303030">
      <DialogCloseBtn @close-dialog="clientAgreementStore.selectedTierTypeForm = false" />

      <v-card-text class="pa-4 pb-0">
        <h3 class="font-weight-regular text-center">Select Type</h3>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12" v-if="clientAgreementStore.tierType?.tierType === 'Hybrid'">
              <v-container fluid>
                <v-row dense>
                  <v-col
                    cols="12"
                    lg="6"
                    v-for="tier in clientAgreementStore.tierType?.feeTiers"
                    :key="tier"
                    class="pb-0 text-center"
                  >
                    <v-card
                      border
                      :color="
                        JobApplicationStore.tierName === tier.name ? '#b2d250' : '#303030'
                      "
                      size="34"
                      v-bind="props"
                      @click="JobApplicationStore.tierName = tier.name"
                      class="pa-2 w-100 d-flex justify-space-between"
                      rounded="lg"
                    >
                      <p>{{ tier.name }}</p>

                      <div>
                        <p>{{ tier.basis }}</p>
                        <p>{{ tier.fee }}</p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center flex-wrap gap-3">
        <v-btn @click="onSubmit()" variant="tonal" color="#74a942"> Submit </v-btn>
        <v-btn
          @click="clientAgreementStore.selectedTierTypeForm = false"
          variant="tonal"
          color="red"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <ConfirmationDialoge
    v-model="confirmDialogStage"
    @action-yes="onJoining()"
    @update-model="confirmDialogStage = false"
    title="Joined"
    text="Are you sure you want to Joined?"
  />
</template>

<script setup>
import DialogCloseBtn from "@/components/SharedComponent/DialogCloseBtn.vue";
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { inject, defineProps, ref, computed } from "vue";
import { useClientAgreementStore } from "@/store/Client/ClientAgreementStore";
import { getYYYYMMDDFormat } from "@/composables/DateTime";

const $cookies = inject("$cookies");
const userId = $cookies.get("user").id;
const props = defineProps(["dialog", "data"]);
const clientAgreementStore = useClientAgreementStore();
const JobApplicationStore = useJobApplicationStore();
const confirmDialogStage = ref(false);
const refForm = ref();
const onSubmit = () => {
  confirmDialogStage.value = true;
};
const newDate = computed(() => {
  return getYYYYMMDDFormat(JobApplicationStore.joinedOn);
});
const onJoining = async () => {
  let data = {
    joinedOn: newDate.value,
    comment: JobApplicationStore.comment,
    tierName: JobApplicationStore.tierName,
  };
  confirmDialogStage.value = false;
  clientAgreementStore.selectedTierTypeForm = false;
  await JobApplicationStore.joinPlacement(JobApplicationStore.jobApplicationId, data);
  await JobApplicationStore.getPlacements(userId);
};
</script>
