import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { getYYYYMMDDFormat } from "@/composables/DateTime";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";

export const useInterviewStore = defineStore("interviewStore", {
  state: () => ({
    jobApplicationStore: useJobApplicationStore(),
    isLoading: false,
    jobApplication: {},
    interviews: [],
    teamInterviews: [],
    userTab: 0,
    listTab: 0,
    search: null,
    rescheduleDialog: false,
  }),
  actions: {
    async getInterviews(userId) {
      this.isLoading = true;
      await api
        .get(`/users/${userId}/interviews/on-going`)
        .then((res) => {
          this.interviews = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getTeamInterviews(teamId) {
      this.isLoading = true;
      await api
        .get(`/teams/${teamId}/interviews`)
        .then((res) => {
          this.teamInterviews = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async scheduleInterview(interviewData) {
      this.isLoading = true;
      await api
        .post(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview `,
          interviewData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async cancelInterview(interviewId, comment) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/cancel`,
          comment
        )
        .then((res) => {
          const data = this.interviews.filter((interview) => {
            return interview.id.includes(this.selectedInterviewId);
          });
          const index = this.interviews.indexOf(data);
          this.interviews.splice(index, 1);
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async completeInterview(interviewId, comment) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/complete`,
          comment
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async passInterview(interviewId, comment) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/pass`,
          comment
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async approveInterview(interviewId) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/approve`
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async failInterview(interviewId, comment) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/fail`,
          comment
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async rescheduleInterview(interviewId, data) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/${interviewId}/reschedule`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateCompletedInterview(comment) {
      this.isLoading = true;
      await api
        .put(
          `/job-applications/${this.jobApplicationStore.jobApplication.id}/interview/completed`,
          comment
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    updateSelectedId(interview) {
      this.selectedInterviewId = interview.id;
      this.selectedJobApplicationId = interview.jobApplication.id;
    },
    immediateCount(interviews) {
      return interviews.filter((interview) => {
        return interview.jobApplication.noticePeriodStatus === "IMMEDIATE";
      }).length;
    },
    servingCount(interviews) {
      return interviews.filter((interview) => {
        return interview.jobApplication.noticePeriodStatus === "SERVING";
      }).length;
    },
    notServingCount(interviews) {
      return interviews.filter((interview) => {
        return interview.jobApplication.noticePeriodStatus === "NOT_SERVING";
      }).length;
    },
  },
  getters: {
    getJobApplication() {
      return (this.jobApplication = this.jobApplicationStore.jobApplication);
    },
    allInterviews() {
      if (this.search) {
        return this.interviews.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return this.interviews;
      }
    },
    todaysInterview() {
      const todayDate = new Date();
      const todayData = this.interviews.filter(
        (interview) =>
          interview.dateTime.split(" ")[0] == getYYYYMMDDFormat(todayDate)
      );
      if (this.search) {
        return todayData.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return todayData;
      }
    },
    upcommingInterviews() {
      const todayDate = new Date();
      const futureData = this.interviews.filter((interview) => {
        const interviewDate = interview.dateTime.split(" ")[0];
        const today = getYYYYMMDDFormat(todayDate);
        return interviewDate > today;
      });
      if (this.search) {
        return futureData.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.jobApplication.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.jobApplication.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return futureData;
      }
    },
    allTeamInterviews() {
      if (this.search) {
        return this.teamInterviews.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return this.teamInterviews;
      }
    },
    todaysTeamInterview() {
      const todayDate = new Date();
      const todayData = this.teamInterviews.filter(
        (interview) =>
          interview.dateTime.split(" ")[0] == getYYYYMMDDFormat(todayDate)
      );
      if (this.search) {
        return todayData.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return todayData;
      }
    },
    upcommingTeamInterviews() {
      const todayDate = new Date();
      const futureData = this.teamInterviews.filter((interview) => {
        const interviewDate = interview.dateTime.split(" ")[0];
        const today = getYYYYMMDDFormat(todayDate);
        return interviewDate > today;
      });
      if (this.search) {
        return futureData.filter((data) => {
          let candidateName =
            data.jobApplication.candidate.firstName +
            " " +
            data.jobApplication.candidate.lastName;
          return (
            candidateName
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.jobApplication.job.client.name
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase()) ||
            data.jobApplication.job.title
              .toLowerCase()
              .includes(this.search.toLocaleLowerCase())
          );
        });
      } else {
        return futureData;
      }
    },
  },
});
