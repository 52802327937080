<template>
  <VSelect
    theme="dark"
    variant="outlined"
    :density="density"
    :label="label"
    rounded="lg"
    :color="color"
    class="text-grey-lighten-4"
    clearable
  />
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["type", "label", "value", "density"]);
const density = props.density ? props.density : "compact";
const label = props.label ? props.label : "";
const color = props.color ? props.color : "#b2d250";
</script>
