export const hexToRgb = (hex) => {
  if (
    typeof hex !== "string" ||
    !hex.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
  ) {
    return null;
  }
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
};
