<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps({
  size: {
    type: String,
    required: false,
    default: "small",
  },
  iconSize: {
    type: String,
    required: false,
    default: "x-small",
  },
});
const emits = defineEmits(["close-dialog"]);
const onClose = () => {
  emits("close-dialog");
};
</script>

<template>
  <v-hover>
    <v-avatar
    style="position: absolute; top: 0; right: 0; cursor: pointer; z-index: 10"
    variant="tonal"
    :size="props.size"
    class="ma-1 elevation-16 closeBtn"
    @click="onClose()"
    rounded="lg"
  >
    <v-icon :size="props.iconSize">mdi-close</v-icon>
  </v-avatar>
  </v-hover>
</template>

<style>
.closeBtn:hover {
  transform: scale(1.1);
  color: rgb(246, 83, 83)
}
</style>
