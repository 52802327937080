<template>
  <v-text-field
    rounded="lg"
    variant="outlined"
    :autofocus="autofocus"
    :label="label"
    :density="density"
    :color="color"
    class="text-grey-lighten-2"
  ></v-text-field>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps(["label", "value", "density", "color", "autofocus"]);
const density = props.density ? props.density : "compact";
const label = props.label ? props.label : "";
const color = props.color ? props.color : "#b2d250";
const autofocus = props.autofocus ? props.autofocus : false;
</script>

<style>
.v-field--variant-outlined .v-field__outline__notch {
  display: unset !important;
}
</style>
