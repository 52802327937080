<template>
  <v-dialog v-model="dialog" activator="parent" max-width="450">
    <v-card color="#303030">
      <v-avatar
        style="
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 10;
        "
        variant="tonal"
        size="small"
        class="ma-1 elevation-16 closeBtn"
        @click="dialog = false"
        rounded="lg"
      >
        <v-icon size="x-small">mdi-close</v-icon>
      </v-avatar>
      <div v-if="!selectedAction">
        <v-container fluid>
          <v-row dense>
            <v-col
              cols="12"
              v-for="action in actionsArray"
              :key="action"
              class="text-center"
            >
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  border
                  :color="isHovering ? cardColor : '#303030'"
                  size="34"
                  v-bind="props"
                  @click="selectedAction = action"
                  class="pa-2 w-100 d-flex justify-center"
                  rounded="lg"
                  >{{ action }}</v-card
                >
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="selectedAction === 'Candidate Info'">
        <v-card-text>
          <h3 class="font-weight-regular text-center">Update Candidate</h3>

          <v-row class="mt-3" no-gutters>
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="firstName"
                label="First Name"
                :rules="[requiredValidator]"
              />
            </v-col>
            <v-col cols="12">
              <CustomTextFieldComponent
                v-model="lastName"
                label="Last Name"
                :rules="[requiredValidator]"
              />
            </v-col>
            <v-col cols="12">
              <CustomSelectComponent
                v-model="gender"
                label="Gender"
                :rules="[requiredValidator]"
                :items="['Male', 'Female']"
              ></CustomSelectComponent>
            </v-col>
            <v-col cols="12">
              <CustomSelectComponent
                v-model="profession"
                label="Profession"
                :rules="[requiredValidator]"
                :items="corporateProfessions"
              ></CustomSelectComponent>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn variant="tonal" color="green" @click="confirmDialogJob = true"
            >Update</v-btn
          >
          <v-btn variant="tonal" color="red" @click="dialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </div>
      <div v-if="selectedAction === 'Candidate Numbers'">
        <v-card-text>
          <h3 class="font-weight-regular text-center">Edit Numbers</h3>

          <v-row class="mt-3" no-gutters>
            <v-col
              cols="12"
              v-for="(number, i) in props.item.phoneNumbers"
              :key="i"
              class="d-flex"
            >
              <CustomTextFieldComponent
                v-model.stop="numbers[i].number"
                type="text"
                @input="$event.target.value = $event.target.value.slice(0, 10)"
                :maxlength="10"
                :rules="[
                  requiredValidator,
                  mobileNumberValidator,
                  nonNegativeNumberValidator,
                ]"
                label="Number"
                class="me-2"
              />
              <v-btn
                icon="mdi-check-circle"
                @click="updateNumber(props.item.id, numbers[i])"
                size="small"
                variant="tonal"
                color="green"
                class="me-2"
              >
                <v-icon size="large">mdi-check-circle</v-icon>
                <v-tooltip
                  text="Update Number"
                  activator="parent"
                  location="bottom"
                ></v-tooltip>
              </v-btn>
              <v-btn
                icon="mdi-close-circle"
                @click="removeNumber(props.item.id, numbers[i].id)"
                size="small"
                variant="tonal"
                color="red"
              >
                <v-icon size="large">mdi-close-circle</v-icon>
                <v-tooltip
                  text="Delete Number"
                  activator="parent"
                  location="bottom"
                ></v-tooltip>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn icon @click="isAddNewNumber = true" size="small">
                <v-icon size="large" color="green">mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isAddNewNumber">
              <v-form ref="refNumber">
                <CustomTextFieldComponent
                  v-model.stop="newNumber"
                  type="text"
                  @input="
                    $event.target.value = $event.target.value.slice(0, 10)
                  "
                  :maxlength="10"
                  :rules="[
                    requiredValidator,
                    mobileNumberValidator,
                    nonNegativeNumberValidator,
                  ]"
                  label="Number"
                  class="me-2"
              /></v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="isAddNewNumber">
          <v-btn variant="tonal" color="green" @click="onNewNumberAdd()"
            >Add Number</v-btn
          >
        </v-card-actions>
      </div>
      <div v-if="selectedAction === 'Candidate Emails'">
        <v-card-text>
          <h3 class="font-weight-regular text-center">Edit Emails</h3>

          <v-row class="mt-3" no-gutters>
            <v-col
              cols="12"
              v-for="(email, i) in props.item.emails"
              :key="i"
              class="d-flex"
            >
              <CustomTextFieldComponent
                v-model="emails[i].email"
                :rules="[requiredValidator, emailValidator]"
                label="Email"
                class="me-2"
              />
              <v-btn
                icon="mdi-check-circle"
                @click="updateEmail(props.item.id, emails[i])"
                size="small"
                variant="tonal"
                color="green"
                class="me-2"
              >
                <v-icon size="large">mdi-check-circle</v-icon>
                <v-tooltip
                  text="Update Email"
                  activator="parent"
                  location="bottom"
                ></v-tooltip>
              </v-btn>
              <v-btn
                icon="mdi-close-circle"
                @click="removeEmail(props.item.id, emails[i].id)"
                size="small"
                variant="tonal"
                color="red"
              >
                <v-icon size="large">mdi-close-circle</v-icon>
                <v-tooltip
                  text="Delete Email"
                  activator="parent"
                  location="bottom"
                ></v-tooltip>
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-btn icon @click="isAddNewEmail = true" size="small">
                <v-icon size="large" color="green">mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isAddNewEmail">
              <v-form ref="refEmail">
                <CustomTextFieldComponent
                  v-model="newEmail"
                  :rules="[requiredValidator, emailValidator]"
                  label="Email"
              /></v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="isAddNewEmail">
          <v-btn variant="tonal" color="green" @click="onNewEmailAdd()"
            >Add email</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
  <LoaderDialog :model-value="jobStore.isLoading" />
  <ConfirmationDialoge
    v-model="confirmDialogJob"
    @action-yes="onUpdate()"
    @update-model="confirmDialogJob = false"
    title="Update Candidate"
    text="Are you sure you want to Update Candidate?"
  />
  <ConfirmationDialoge
    v-model="removeConfirmationDialog"
    @action-yes="removeNumber()"
    @update-model="removeConfirmationDialog = false"
    title="Update Candidate"
    text="Are you sure you want to Update Candidate?"
  />
  <ConfirmationDialoge
    v-model="confirmNewNumberAddDialog"
    @action-yes="addNewNumber()"
    @update-model="confirmNewNumberAddDialog = false"
    title="Add Number"
    text="Are you sure you want to Add Number?"
  />
  <ConfirmationDialoge
    v-model="confirmNewEmailAddDialog"
    @action-yes="addNewEmail()"
    @update-model="confirmNewEmailAddDialog = false"
    title="Add Email"
    text="Are you sure you want to Add Email?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CustomTextFieldComponent from "@/components/SharedComponent/CustomTextFieldComponent.vue";
import CustomSelectComponent from "@/components/SharedComponent/CustomSelectComponent.vue";
import { ref, defineProps, watch } from "vue";
import { corporateProfessions } from "@/composables/CandidateProfession";
import { useJobStore } from "@/store/Job/JobStore";
import { useCandidateStore } from "@/store/Candidate/CandidateStore";
import { requiredValidator } from "@/composables/Validators";
import { mobileNumberValidator } from "@/composables/Validators";
import { emailValidator } from "@/composables/Validators";
import { nonNegativeNumberValidator } from "@/composables/Validators";
import { hexToRgb } from "@/composables/Colors";
const cardColor = `rgba(${hexToRgb("#b2d250")}, 0.3)`;
const candidateStore = useCandidateStore();
const props = defineProps(["item"]);
const jobStore = useJobStore();
const dialog = ref(false);
const confirmDialogJob = ref(false);
const removeConfirmationDialog = ref(false);
const confirmNewNumberAddDialog = ref(false);
const confirmNewEmailAddDialog = ref(false);
const selectedAction = ref(null);
const isAddNewNumber = ref(false);
const isAddNewEmail = ref(false);
const refNumber = ref(null);
const refEmail = ref(null);
const actionsArray = [
  "Candidate Info",
  "Candidate Numbers",
  "Candidate Emails",
];
const firstName = ref(props.item?.fullName.split(" ")[0]);
const lastName = ref(props.item?.fullName.split(" ")[1]);
const numbers = ref([...props.item?.phoneNumbers]);
const emails = ref([...props.item?.emails]);
const newNumber = ref();
const newEmail = ref();
const gender = ref(props.item?.gender);
const profession = ref(props.item?.profession);

const onUpdate = async () => {
  let candidate = {
    firstName: firstName.value,
    lastName: lastName.value,
    gender: gender.value,
    profession: profession.value,
  };
  confirmDialogJob.value = false;
  dialog.value = false;
  await candidateStore.updateCandidate(props.item.id, candidate);
};

const removeNumber = (candidateId, numberId) => {
  candidateStore.removeCandidateNumber(candidateId, numberId);
};
const updateNumber = (candidateId, numberData) => {
  let data = {
    type: numberData.type,
    number: numberData.number,
  };
  candidateStore.updateCandidateNumber(candidateId, numberData.id, data);
};
const onNewNumberAdd = () => {
  refNumber.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmNewNumberAddDialog.value = true;
    }
  });
};
const addNewNumber = () => {
  let data = {
    type: "Personal",
    number: newNumber.value,
  };
  candidateStore.addCandidateNumber(props.item.id, data);
  confirmNewNumberAddDialog.value = false;
  newNumber.value = "";
  isAddNewNumber.value = false;
};
////
const removeEmail = (candidateId, mailId) => {
  candidateStore.removeCandidateEmail(candidateId, mailId);
};
const updateEmail = (candidateId, emailData) => {
  let data = {
    type: emailData.type,
    email: emailData.email,
  };
  candidateStore.updateCandidateEmail(candidateId, emailData.id, data);
};
const onNewEmailAdd = () => {
  refEmail.value?.validate().then((valid) => {
    if (valid.valid) {
      confirmNewEmailAddDialog.value = true;
    }
  });
};
const addNewEmail = () => {
  let data = {
    type: "Personal",
    email: newEmail.value,
  };
  candidateStore.addCandidateEmail(props.item.id, data);
  confirmNewEmailAddDialog.value = false;
  newEmail.value = "";
  isAddNewEmail.value = false;
};
watch(
  () => dialog.value,
  () => {
    if (!dialog.value) {
      selectedAction.value = null;
    }
  }
);
</script>

<style scoped>
.closeBtn:hover {
  transform: scale(1.1);
}
.close-button {
  border: 2px solid grey;
  border-style: dashed;
}
</style>
