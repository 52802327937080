<template>
  <div>
    <v-card-text>
      <div class="d-flex">
        <div>
          <h2 class="font-weight-regular text-center">
            {{ `Kindly provide an update on the status of ${props.level}` }}
          </h2>

          <div v-for="interview in props.interviews" :key="interview">
            <div
              v-if="interview.level === props.level"
              class="d-flex flex-row w-100"
            >
              <div>
                <v-card
                  color="transparent"
                  border
                  width="330"
                  height="100"
                  class="me-3 flex-0-1"
                >
                  <div>
                    <v-card-text class="pa-2">
                      <div class="d-flex justify-space-between">
                        <div>
                          <div class="d-flex">
                            <v-icon size="small" class="me-1 align-self-center">
                              mdi-calendar-check
                            </v-icon>
                            <h3 class="font-weight-regular">
                              {{ interview.title }}
                            </h3>
                          </div>
                          <div class="d-flex mt-2">
                            <v-icon size="small" class="me-1 align-self-center">
                              mdi-clock-time-four-outline
                            </v-icon>
                            <h4 class="font-weight-regular text-grey-lighten-2">
                              {{
                                getMonthDateYearTimeFormat(interview.dateTime)
                              }}
                            </h4>
                          </div>
                        </div>
                        <div>
                          <v-chip rounded="lg" class="pa-2" color="grey">{{
                            interview.status
                          }}</v-chip>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </v-card>
                <h4 class="font-weight-regular mt-3">
                  {{ getAcknowledgementMessage(interview) }}
                </h4>
                <div class="d-flex me-3 mt-3">
                  <v-card
                    class="justify-center w-50 me-3"
                    :color="
                      isAcknowledgementUploaded(interview) ? 'green' : 'red'
                    "
                    variant="tonal"
                    @click="viewAcknowledgment(interview.acknowledgementUrl)"
                  >
                    <v-card-text class="pa-2 d-flex flex-column justify-center">
                      <v-icon size="x-large" class="mx-auto">
                        mdi-image
                      </v-icon>
                      <h4 class="font-weight-regular text-center">
                        Acknowledgment
                      </h4>
                    </v-card-text>
                    <UpdateInterviewAcknowledgment
                      v-if="
                        !isAcknowledgementUploaded(interview) &&
                        !isAcknowledgementAprroved(interview)
                      "
                      :interviewId="interview.id"
                    />
                  </v-card>
                  <v-card
                    class="justify-center w-50"
                    :color="
                      isAcknowledgementAprroved(interview) ? 'green' : 'red'
                    "
                    @click="confirmApproveInterview(interview)"
                    variant="tonal"
                  >
                    <v-card-text class="pa-2 d-flex flex-column justify-center">
                      <v-icon size="x-large" class="mx-auto">
                        mdi-check-circle
                      </v-icon>
                      <h4 class="font-weight-regular text-center">
                        {{
                          isAcknowledgementAprroved(interview)
                            ? "Approved"
                            : "Approve"
                        }}
                      </h4>
                    </v-card-text>
                  </v-card>
                </div>
              </div>

              <div class="d-flex flex-column" style="">
                <div class="d-flex mt-2" style="flex: 1">
                  <v-btn
                    @click="selectedInterviewId = interview.id"
                    prepend-icon="mdi-calendar"
                    color="blue"
                    class="me-3"
                    style="width: 150px"
                    variant="tonal"
                    size="small"
                    ><p>Reschedule</p>
                    <RescheduleDialog
                      title="Reschedule Interview"
                      @reschedule-intervew="rescheduleInterview"
                    />
                  </v-btn>
                  <div>
                    <h4 class="font-weight-regular">
                      If the interview has been rescheduled, then use this
                      option.
                    </h4>
                  </div>
                </div>
                <v-divider></v-divider>
                <h5
                  v-if="!isAcknowledgementAprroved(interview)"
                  class="pa-1 font-weight-regular text-orange"
                >
                  <v-icon class="me-2">mdi-alert-circle-outline</v-icon>
                  <span
                    >Alert : You can't proceed furthur without acknowledgement
                    approval</span
                  >
                </h5>
                <div class="d-flex mt-2" style="flex: 1">
                  <v-btn
                    color="green"
                    variant="tonal"
                    size="small"
                    style="width: 150px"
                    class="me-2"
                    :disabled="!isAcknowledgementAprroved(interview)"
                    prepend-icon="mdi-check"
                    @click="confirmCompleteInterview(interview.id)"
                    >Complete
                  </v-btn>
                  <h4 class="font-weight-regular">
                    If you have received interview feedback, then use this
                    option.
                  </h4>
                </div>
                <v-divider></v-divider>

                <div class="d-flex mt-2" style="flex: 1">
                  <v-btn
                    @click="selectedInterviewId = interview.id"
                    variant="tonal"
                    size="small"
                    style="width: 150px"
                    class="me-2"
                    color="red"
                    prepend-icon="mdi-cancel"
                    ><p>Cancel</p>
                    <CommentDialog
                      @cancel-interview="cancelInterview"
                      title="Cancel Interview"
                  /></v-btn>
                  <h4 class="font-weight-regular">
                    If the interview has been cancelled, then use this option.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </div>

  <ConfirmationDialoge
    v-model="completeInterviewDialog"
    @action-yes="completeInterview()"
    @update-model="completeInterviewDialog = false"
    title="Complete Interview"
    text="Are you sure you want to Complete Interview?"
  />
  <ConfirmationDialoge
    v-model="approveInterview"
    @action-yes="approveInterviewAck()"
    @update-model="approveInterview = false"
    title="Approve Interview"
    text="Are you sure you want to apporve interview?"
  />
</template>

<script setup>
import ConfirmationDialoge from "@/components/SharedComponent/dialogs/ConfirmationDialoge.vue";
import CommentDialog from "@/components/JobAplication/dialogs/CommentDialog.vue";
import RescheduleDialog from "@/components/Interview/dialogs/RescheduleDialog.vue";
import UpdateInterviewAcknowledgment from "@/components/JobAplication/dialogs/UpdateInterviewAcknowledgment.vue";
import { getMonthDateYearTimeFormat } from "@/composables/DateTime";
import { useJobApplicationStore } from "@/store/JobApplications/JobApplicationStore";
import { ref } from "vue";
import { defineProps } from "vue";
import { toast } from "vue3-toastify";
import { useInterviewStore } from "@/store/JobApplications/InterviewStore";
const jobApplicationStore = useJobApplicationStore();
const interviewStore = useInterviewStore();
const props = defineProps(["level", "interviews"]);
const selectedInterviewId = ref("");
const approveInterview = ref(false);
const completeInterviewDialog = ref(false);

const isAcknowledgementAprroved = (interview) => {
  return interview.approved;
};
const isAcknowledgementUploaded = (interview) => {
  return interview.acknowledgementUrl ? true : false;
};

const approveInterviewAck = async () => {
  approveInterview.value = false;
  await interviewStore.approveInterview(selectedInterviewId.value);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const viewAcknowledgment = (acknowledgementUrl) => {
  const url = acknowledgementUrl ? acknowledgementUrl : null;
  jobApplicationStore.showAcknowledge(url);
};
const getAcknowledgementMessage = (interview) => {
  return interview.acknowledgementUrl
    ? interview.approved
      ? "Acknowledgement is approved"
      : "Acknowledgement is not approved"
    : "Acknowledgement is not uploaded";
};
const confirmApproveInterview = (interview) => {
  if (jobApplicationStore.showInterviewApproveButtons()) {
    if (isAcknowledgementUploaded(interview)) {
      selectedInterviewId.value = interview.id;
      approveInterview.value = true;
    } else {
      toast.warn("Can't approve without Aknowledgement", {
        autoClose: 1500,
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        transition: toast.TRANSITIONS.ZOOM,
      });
    }
  } else {
    toast.warn("You're not allowed to Approve", {
      autoClose: 1500,
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      transition: toast.TRANSITIONS.ZOOM,
    });
  }
};
const rescheduleInterview = async (rescheduleData) => {
  let acknowledgement = rescheduleData.file ? rescheduleData.file : null;
  const formData = new FormData();
  formData.append("dateTime", rescheduleData.dateTime);
  formData.append("comment", rescheduleData.comment);
  formData.append("acknowledgement", acknowledgement);
  await interviewStore.rescheduleInterview(selectedInterviewId.value, formData);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const cancelInterview = async (comment) => {
  await interviewStore.cancelInterview(selectedInterviewId.value, comment);
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
const confirmCompleteInterview = (interviewId) => {
  selectedInterviewId.value = interviewId;
  completeInterviewDialog.value = true;
};
const completeInterview = async () => {
  const comment =
    "Interview has been successfully completed, and the process is now over";
  completeInterviewDialog.value = false;
  await interviewStore.completeInterview(selectedInterviewId.value, {
    comment,
  });
  await jobApplicationStore.getJobApplication(
    jobApplicationStore.jobApplication.id
  );
};
</script>