import { defineStore } from "pinia";
import api from "@/services/api";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";

export const useClientAgreementStore = defineStore("clientAgreementStore", {
  state: () => ({
    isLoading: false,
    agreementDetailsOverlay: false,
    agreementType: null,
    aggrementSelectTierDailog: false,
    PreviousAggrementSelectTierDailog: false,
    aggrementForm: false,
    updateAggrementForm: false,
    previousAggrementForm: false,
    selectedTierTypeForm: false,
    agreements: [],
    tierType: null,
    client: null,
    clientId: useRoute().params.id,
    selectedAgreement: null,
    selectedTierType: null,
  }),
  actions: {
    viewAgreements(clientData) {
      this.client = clientData;
      this.agreements = clientData.agreements;
      this.router.push("/agreements");
    },
    viewAgreementDetails(agreement) {
      this.isLoading = true;
      this.selectedAgreement = agreement;
      setTimeout(() => {
        this.isLoading = false;
        this.agreementDetailsOverlay = true;
      }, 500);
    },
    async getAgreementTierTypes(id) {
      this.isLoading = true;
      await api
        .get(`/client/${id}/agreement/tier-type`)
        .then((res) => {
          this.tierType = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getAgreements(id) {
      this.isLoading = true;
      await api
        .get(`/client/${id}/agreement`)
        .then((res) => {
          this.agreements = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async approveAgreement() {
      this.isLoading = false;
      await api
        .put(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}/approve`
        )
        .then((res) => {
          if (res.status === 200) {
            const index = this.agreements.findIndex(
              (agreement) => agreement.id === this.selectedAgreement.id
            );
            if (index !== -1) {
              this.agreements[index].approved = true;
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async activateAgreement() {
      this.isLoading = true;
      api
        .put(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}/activate`
        )
        .then((res) => {
          if (res.status === 200) {
            const index = this.agreements.findIndex(
              (agreement) => agreement.id === this.selectedAgreement.id
            );
            if (index !== -1) {
              this.agreements[index].active = true;
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deactivateAgreement() {
      this.isLoading = true;
      api
        .put(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}/deactivate`
        )
        .then((res) => {
          if (res.status === 200) {
            const index = this.agreements.findIndex(
              (agreement) => agreement.id === this.selectedAgreement.id
            );
            if (index !== -1) {
              this.agreements[index].active = false;
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateAgreement(agreementData) {
      this.isLoading = true;
      api
        .put(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}`,
          agreementData
        )
        .then((res) => {
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async signAgreement() {
      this.isLoading = true;
      api
        .put(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}/sign`
        )
        .then((res) => {
          if (res.status === 200) {
            const index = this.agreements.findIndex(
              (agreement) => agreement.id === this.selectedAgreement.id
            );
            if (index !== -1) {
              this.agreements[index].signed = true;
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async deleteAgreement() {
      this.isLoading = true;
      api
        .delete(
          `/client/${this.clientId}/agreement/${this.selectedAgreement.id}`
        )
        .then((res) => {
          if (res.status === 200) {
            const index = this.agreements.findIndex(
              (agreement) => agreement.id === this.selectedAgreement.id
            );
            if (index !== -1) {
              this.agreements.splice(index, 1);
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },
});
