import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useDialogStore } from "@/store/DrawerStore";
import VueCookies from "vue-cookies";
const userId = VueCookies.get("user")?.id;

export const useCandidateStore = defineStore("candidateStore", {
  state: () => ({
    dialogStore: useDialogStore(),

    isLoading: false,
    duplicateCandidates: {},
    limitApplication: {},
    jobDetails: {},
    seniorId: "",
    //personal Info
    jobId: "",
    firstName: null,
    lastName: null,
    email: null,
    number: 0,
    gender: null,
    currentLocation: null,
    preferredLocation: null,
    remark: null,
    profession: null,
    callStatus: null,
    source: null,
    linkedinFrom: null,
    //CV details
    resumeUpdated: false,
    cv: null,
    // acknowledge
    acknowledged: false,
    acknowledgement: null,
    //experience
    currentDesignation: null,
    currentCompany: null,
    responsibilities: [],
    currentSalary: null,
    expectedSalary: null,
    immediateJoiner: false,
    noticePeriodDays: 0,
    negotiable: false,
    noticePeriodStatus: null,
    lastWorkingDate: null,
    relevantExperience: 0,
    totalExperience: 0,
    expectedJoiningDate: null,
    noticePeriod: null,
    candidates: [],
    searchedCandidates: [],
    formattedCandidate: null,
  }),

  actions: {
    setResumeFile(file) {
      this.cv = file;
    },
    setAcknowledgementFile(file) {
      this.acknowledgement = file;
    },

    resetData() {
      this.jobId = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.number = 0;
      this.gender = "";
      this.currentLocation = "";
      this.preferredLocation = "";
      this.remark = "";
      this.callStatus = "";
      this.source = "";
      this.linkedinFrom = "";
      this.resumeUpdated = false;
      this.cv = null;
      this.acknowledged = false;
      this.acknowledgement = null;
      this.currentDesignation = "";
      this.currentCompany = "";
      this.responsibilities = [];
      this.currentSalary = 0;
      this.expectedSalary = 0;
      this.immediateJoiner = false;
      this.noticePeriodDays = 0;
      this.negotiable = false;
      this.noticePeriodStatus = "";
      this.lastWorkingDate = "";
      this.relevantExperience = 0;
      this.totalExperience = 0;
      this.expectedJoiningDate = "";
      this.noticePeriod = "";
    },
    async addCandidate(allocation) {
      this.jobId = allocation.jobId;
      this.seniorId = allocation.seniorId;
      const data = {
        jobId: this.jobId,
        recruiterId: userId,
      };
      await this.checkLimit(data);
      if (this.limitApplication.limitExceeded) {
        this.dialogStore.limitCandidatesDialog = true;
      } else {
        (this.isLoading = true),
          await api
            .get(`/jobs/${this.jobId}/for-job-application`)
            .then((res) => {
              this.jobDetails = res.data;
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              return err;
            });
        this.dialogStore.formDrawer = true;
      }
    },
    async addCandidateEndpoint(data) {
      this.isLoading = false;
      await api
        .post("/candidates", data)
        .then((res) => {
          toast.success(res.data.message, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addJobApplication(formData) {
      (this.isLoading = true),
        await api
          .post("/job-applications", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
            setTimeout(() => {
              this.resetData();
            }, 10000);
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    async checkCandidateDuplicate() {
      this.isLoading = true;
      await api
        .post("/job-applications/check-duplicate", {
          email: this.email,
          phoneNumber: this.number,
          jobId: this.jobDetails.id,
        })
        .then((res) => {
          this.duplicateCandidates = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async checkLimit(data) {
      this.isLoading = true;
      await api
        .post("/job-applications/check-limit", data)
        .then((res) => {
          this.limitApplication = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getCandidates() {
      this.isLoading = true;
      await api
        .get("/candidate")
        .then((res) => {
          this.candidates = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateCandidate(candidateId, candidateData) {
      this.isLoading = true;
      await api
        .put(`/candidates/${candidateId}/update`, candidateData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Updated Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    async addCandidateNumber(candidateId, numberData) {
      this.isLoading = true;
      await api
        .post(`/candidates/${candidateId}/number`, numberData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Number Added Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateCandidateNumber(candidateId, numberId, numberData) {
      this.isLoading = true;
      await api
        .put(`/candidates/${candidateId}/number/${numberId}`, numberData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Number Added Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async removeCandidateNumber(candidateId, numberId) {
      this.isLoading = true;
      await api
        .delete(`/candidates/${candidateId}/number/${numberId}`)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Number Removed Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addCandidateEmail(candidateId, emailData) {
      this.isLoading = true;
      await api
        .post(`/candidates/${candidateId}/email`, emailData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Email Added Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateCandidateEmail(candidateId, emailId, emailData) {
      this.isLoading = true;
      await api
        .put(`/candidates/${candidateId}/email/${emailId}`, emailData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Email Added Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async removeCandidateEmail(candidateId, emailId) {
      this.isLoading = true;
      await api
        .delete(`/candidates/${candidateId}/email/${emailId}`)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Candidate Email Removed Successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async searchCandidate(search) {
      this.isLoading = true;
      await api
        .get(`/candidates/search/${search}`)
        .then((res) => {
          this.searchedCandidates = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    getFormattedSelectedCandidate(candidate, jobApplication) {
      const data = {
        candidateName: candidate.fullName,
        candidateEmail: candidate.emails ? candidate.emails[0] : "",
        candidateNumber: candidate.phoneNumbers
          ? candidate.phoneNumbers[0].number
          : "",
        jobTitle: jobApplication.jobTitle,
        clientName: jobApplication.clientName,
        recruiterName: jobApplication.recruiterName,
        jobApplicationId: jobApplication.id,
      };
      this.formattedCandidate = data;
    },
  },
  getters: {
    isEmailValid() {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.email) {
        if (pattern.test(this.email)) {
          return true;
        } else {
          this.number = "";
          return false;
        }
      } else {
        this.number = "";
        return false;
      }
    },
  },
  persist: true,
});
