<template>
  <v-app class="secondaryDarkColor">
    <v-layout>
      <!-- <SideNavigation /> -->
      <v-main>
        <component :is="$route.meta.layout || 'div'">
          <router-view />
        </component>
      </v-main>
    </v-layout>
  </v-app>
  <NoNotificationAlert />
  <NoLocationAlert />
  <SearchDialog />
</template>

<script setup>
import SearchDialog from "@/components/Dashboards/components/SearchDialog.vue";
import NoNotificationAlert from "@/components/NoNotificationAlert.vue";
import NoLocationAlert from "@/components/NoLocationAlert.vue";
import { onMounted, watch } from "vue";
import { useAuthStore } from "@/store/AuthStore";
import { useRouter } from "vue-router";
import { onMessage } from "firebase/messaging";
import { messaging } from "@/firebase/index";
const authStore = useAuthStore();
const router = useRouter();

watch(
  () => authStore.onLine,
  () => {
    if (authStore.onLine) console.log("online", authStore.onLine);
    else authStore.noWifiSnackbar = true;
  }
);
watch(
  () => authStore.isLocation,
  () => {
    authStore.location();
  }
);

onMounted(() => {
  authStore.requestPermission();
  authStore.location();
});
watch(
  () => authStore.permission === "denied",
  () => {
    console.log(authStore.permission);
    authStore.requestPermission();
  }
);
watch(
  () => authStore.snackbar,
  () => {
    if (authStore.snackbar) {
      authStore.notificationAlert = true;
    } else {
      authStore.notificationAlert = false;
    }
  }
);

onMessage(messaging, (payload) => {
  console.log("notificationOptions");

  const notificationTitle = payload.notification.title;

  const notificationOptions = {
    body: payload.notification.body,
    // icon: "/emoji.png",
  };

  new Notification(notificationTitle, notificationOptions);
});
watch(
  () => authStore.isAuthenticated,
  (newVal) => {
    if (newVal === true) router.push({ name: "Dashboard" });
  }
);
watch(
  () => navigator.onLine,
  () => {
    if (navigator.onLine) console.log(true);
    else console.log(false);
  }
);
</script>

<style lang="scss">
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
#app {
  height: 100vh;
  width: 100%;
  overflow: auto;
}
.container {
  height: 92vh;
  display: flex;
  flex-flow: column;
}
.cardElevationDark {
  box-shadow: -2px 6px 26px 2px rgba(0, 0, 0, 0.71);
  -webkit-box-shadow: -2px 6px 26px 2px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: -2px 6px 26px 2px rgba(0, 0, 0, 0.71);
}
.cardElevationMedium {
  box-shadow: -2px 8px 11px 2px rgba(25, 25, 25, 0.71);
  -webkit-box-shadow: -2px 8px 11px 2px rgba(25, 25, 25, 0.71);
  -moz-box-shadow: -2px 8px 11px 2px rgba(25, 25, 25, 0.71);
}
.primaryColor {
  color: #74a942;
}
.primaryLightColor {
  color: #b6d230;
}
.secondaryColor {
  color: #3d3d3d;
}
.secondaryLightColor {
  color: #787878;
}
.secondaryDarkColor {
  background-color: #252525;
}
.lightColor {
  color: #e5e5e5;
}

::-webkit-scrollbar {
  width: 7px;
  border-radius: 50%;
  background-color: rgb(36, 36, 36);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(88, 88, 88);
  height: 10px;
}
::-webkit-scrollbar-thumb:vertical {
  min-height: 100px;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: rgb(71, 71, 71);
  width: 30px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
a {
  text-decoration: none;
  color: rgb(194, 194, 194);
}
.v-overlay .v-overlay__scrim {
  background: rgb(0, 0, 0);
}
/* .v-input .v-input__details{
  display: none;
} */
.v-input--horizontal .v-input__append {
  display: none;
}
.v-checkbox .v-selection-control {
  min-height: 0px !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  padding-left: 10px;
  padding-right: 10px;
}
.fc .fc-button .fc-icon {
  vertical-align: top;
}
.v-selection-control--density-compact .v-selection-control__wrapper {
  margin-inline-start: -0.3125rem;
}
.v-field--variant-outlined .v-field__outline__notch {
  display: none;
}
.v-radio-group .v-selection-control-group .v-radio:not(:last-child) {
  margin-inline-end: 0.9rem;
}
::-webkit-file-upload-button {
  display: none;
}

.displayFlow {
  height: 100%;
  overflow: auto;
}

.user-list-name:hover {
  color: #b2d250;
}
.dp__input_wrap .dp__input {
  background-color: transparent !important;
  line-height: 1.5;
  border: 1px solid rgb(166, 201, 50);
  font-size: 18px;
}
.dp-custom-input {
  color: #fefffe;
  &:hover {
    border-color: #b2d250;
  }
}
.titleText {
  font-size: 15px;
}
.subText {
  font-size: 15px;
}
@media screen and (max-width: 1300px) {
  .titleText {
    font-size: 13px;
  }
  .subText {
    font-size: 13px;
  }
}
</style>
